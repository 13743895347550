import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  authForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.authForm = this.fb.group({
      email: '',
      password: ''
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    const promise = this.authService.signupWithEmailAndPassword(this.authForm.value.email, this.authForm.value.password);

    promise.then(value => {

    }, error => {
      // error.code
      // 'auth/weak-password'
      // 'auth/invalid-email'
      // 'auth/email-already-in-use'
      // 'auth/operation-not-allowed'

    });
  }
}
