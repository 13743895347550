import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { combineLatest, Subscription } from 'rxjs';

import { UtilService } from '../../core/util.service';
import { RoomDoc } from '../../core/schema';
import { trimOrganization, trimSite } from '../../core/util';
import { unifiedOrderVendorMappings, unifiedOrderChannelMappings, unifiedOrderDeliveryTypeMappings } from '../../core/string-map';
import { DialogSpinnerComponent } from '../../shared/dialog-spinner/dialog-spinner.component';
import { RoomService } from '../../core/room.service';
import { SiteService } from '../../core/site.service';

@Component({
  selector: 'app-menu-book-select',
  templateUrl: './menu-book-select.component.html',
  styleUrls: ['./menu-book-select.component.scss']
})
export class MenuBookSelectComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  dialogRef: MatDialogRef<DialogSpinnerComponent, any>;

  unifiedOrderVendorMappings = unifiedOrderVendorMappings;
  unifiedOrderChannelMappings = unifiedOrderChannelMappings;
  unifiedOrderDeliveryTypeMappings = unifiedOrderDeliveryTypeMappings;
  trimOrganization = trimOrganization;
  trimSite = trimSite;

  sites: {
    name: string;
    rooms: RoomDoc[];
  }[] = [];
  rooms: RoomDoc[] = [];

  roomKey: string;
  room: RoomDoc;
  siteControl: FormControl;
  roomControl: FormControl;

  // Form Control
  orderForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private roomService: RoomService,
    private siteService: SiteService,
    private utilService: UtilService,
    private router: Router
  ) { }

  ngOnInit() {
    this.roomKey = this.route.snapshot.paramMap.get('room');

    this.initModelForRoom(this.roomKey);
    this.buildForm();

    this.observeRoom();
    this.observeOrganization();
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  buildForm() {
    this.roomControl = new FormControl(this.roomKey);
    this.siteControl = new FormControl(this.room.siteName);
  }

  /**
   * 다른 필드는 유지하고 room 관련 필드만 변경할 때 사용한다.
   */
  initModelForRoom(roomKey: string) {
    const room = this.roomService.roomForRoomKey(roomKey);
    this.room = room;
  }

  /**
   * 변경된 model의 값을 적용한다.
   */
  updateForm() {
    this.roomControl.setValue(this.roomKey, { emitEvent: false });
    this.siteControl.setValue(this.room.siteName, { emitEvent: false });
  }

  /**
   * roomKey가 변경이 되었을 때 초기화할 작업을 수행한다.
   */
  resetForRoom(roomKey: string) {
    this.roomKey = roomKey;
    this.room = this.roomService.roomForRoomKey(roomKey);

    if (this.room === undefined) {
      this.utilService.toastrError(`${roomKey}에 해당하는 room 정보를 못 찾았습니다. 문제가 있다고 개발자에게 알려주세요.`);
    }

    this.initModelForRoom(this.roomKey);
    this.updateForm();
  }

  private observeOrganization() {
    const siteSubject = this.siteService.latestSubject;
    const roomSubject = this.roomService.latestSubject;

    this.subscription = combineLatest([ siteSubject, roomSubject ]).subscribe(([ siteDocs, roomDocs ]) => {
      // 영업 시작하지 않은 경우는 제외
      const liveRooms = Object.values(roomDocs).filter(roomDoc => roomDoc.live && !roomDoc.virtual);
      const sites = Object.values(siteDocs).map(siteDoc => {
        const rooms = liveRooms.filter(room => room.siteName === siteDoc.siteName);
        return { name: siteDoc.siteName, rooms};
      });

      this.sites = sites;
      // 현재 선택된 room에 대해서 업데이트해야 한다.
      const selectedSite = this.sites.find(site => site.name === this.room.siteName);
      this.rooms = selectedSite.rooms;
    });
  }

  /**
   * 업소를 변경하면 뷰를 초기화해야 한다.
   */
  observeRoom() {
    this.siteControl.valueChanges.forEach(siteName => {
      const site = this.sites.find($site => $site.name === siteName);
      this.rooms = site.rooms;
      if (site) {
        this.roomControl.setValue(site.rooms[0].room);
      } else {
        console.error('No site');
      }
    });

    this.roomControl.valueChanges.forEach(roomKey => {
      this.resetForRoom(roomKey);
      this.router.navigate(['menu', roomKey]);
    });
  }

  /**
   * 모든 control이 변결될 때마다 호출된다.
   * userTel과 deliveryType의 변경때 마다 userTel의 validator를 실행시키기 위해 사용한다.
   */
  formValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      const orderForm = control;
      if (orderForm) {
        // onlySelf를 false로 하면 무한반복한다.
        orderForm.get('userTel').updateValueAndValidity({onlySelf: true});
      }

      return null;
    };
  }
}
