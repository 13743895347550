import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MyMaterialModule } from '../my-material/my-material.module';
import { SharedModule } from '../shared/shared.module';
import { MenuBookSelectComponent } from './menu-book-select/menu-book-select.component';
import { MenuBookComponent } from './menu-book/menu-book.component';

@NgModule({
  declarations: [
    MenuBookSelectComponent,
    MenuBookComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MyMaterialModule,
    SharedModule
  ]
})
export class FormModule { }
