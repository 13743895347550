import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { DialogNoticeService } from '../../shared/dialog-notice/dialog-notice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  authForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private dialogNoticeService: DialogNoticeService
  ) {
  }

  ngOnInit() {
    this.authForm = this.fb.group({
      email: '',
      password: ''
    });
  }

  onSubmit() {
    const promise = this.authService.signinWithEmailAndPassword(this.authForm.value.email, this.authForm.value.password);

    promise.then(value => {
      console.log('navigate');
      this.router.navigate(['/']);
    }, err => {
      this.dialogNoticeService.openSimpleNoticeDialog(err.message);
      // error.code
      // 'auth/weak-password'
      // 'auth/invalid-email'
      // 'auth/email-already-in-use'
      // 'auth/operation-not-allowed'
    });
  }
}
