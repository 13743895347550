import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from './core/auth.service';
import { VersionService } from './core/version.service';
import { UserService } from './core/user.service';
import { LocalConfigurationService } from './core/local-configuration.service';
import { RoomService } from './core/room.service';
import { SiteService } from './core/site.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loggedIn = false;
  email = '';
  myVersion: string;
  latestVersion: string;
  duration = 0;

  private subscription: Subscription = null;
  private versionSubscription: Subscription = null;
  private durationSubscription: Subscription = null;

  title = '고스트키친 메뉴';
  navItems = [
    // {name: '주문 입력', route: '/organization'},
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
    private roomService: RoomService,
    private siteService: SiteService,
    private versionService: VersionService,
    private userService: UserService,
    private localConfigurationService: LocalConfigurationService
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        for (const navItem of this.navItems) {
          if (navItem.route === event.urlAfterRedirects) {
            this.title = `고스트키친 메뉴 - ${navItem.name}`;
            document.title = navItem.name;
            break;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.authService.signinWithEmailAndPassword('guest@toe.cloud', 'guestguest').then(value => {
      if (value === true) {
        this.email = 'guest@toe.cloud';

        // 아래 2가지는 init-guard의 조건이기도 하다.
        // 여기에서 시작을 하고 init-guard에서는 데이터가 수신 완료되는 것을 기다린다.
        // TODO: 로그아웃 후에 기존의 데이터를 초기화하도록 확인한다.
        this.userService.observe(this.email);
        this.siteService.observe();
        this.roomService.observe();
      }
    });

    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;
    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
    if (this.durationSubscription) {
      this.durationSubscription.unsubscribe();
    }
  }

  onChangeDuration(ev: Event) {
    const newDuration = parseInt((ev.target as HTMLInputElement).value, 10);

    this.localConfigurationService.durationSubject.next(newDuration);
  }

  reload() {
    window.location.reload();
  }
}
