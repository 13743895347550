import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { RoomDoc, RoomDocs } from './schema';

const collectionPath = 'room';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  rooms: RoomDocs = {};
  latestSubject = new BehaviorSubject<RoomDocs>({});
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
  ) {
  }

  public observe() {
    const queryFn: QueryFn = ref => {
      return ref.where('organization', '==', 'ghostkitchen');
    };

    const collection = this.db.collection<RoomDoc>(collectionPath, queryFn);
    collection.valueChanges().subscribe(docs => {
      const roomDocs = {};

      for (const doc of docs) {
        roomDocs[doc._id] = doc;
      }

      this.rooms = roomDocs;

      this.latestSubject.next(this.rooms);
    });
  }

  public roomForRoomKey(roomKey: string): RoomDoc {
    return this.rooms[roomKey];
  }
}
