import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { SiteDoc, SiteDocs } from './schema';

const collectionPath = 'site';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class SiteService {
  site: SiteDocs = {};
  latestSubject = new BehaviorSubject<SiteDocs>({});
  subscription: Subscription;

  constructor(
    private db: AngularFirestore,
  ) {
  }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  observe() {
    const queryFn: QueryFn = ref => {
      return ref.where('organization', '==', 'ghostkitchen');
    };

    const collection = this.db.collection<SiteDoc>(collectionPath, queryFn);
    collection.valueChanges().subscribe(docs => {
      const siteDocs = {};

      for (const doc of docs) {
        siteDocs[doc._id] = doc;
      }

      this.site = siteDocs;

      this.latestSubject.next(this.site);
    });
  }
}

