import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from '../my-material/my-material.module';
import { ReactiveFormsModule } from '@angular/forms';

import { DialogNoticeComponent } from './dialog-notice/dialog-notice.component';

import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import { DialogLoginComponent } from './dialog-login/dialog-login.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MyMaterialModule
  ],
  declarations: [
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
  ],
  exports: [],
  entryComponents: [
    DialogNoticeComponent,
    DialogSpinnerComponent,
    DialogLoginComponent,
  ]
})
export class SharedModule { }
