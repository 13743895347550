import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';
import { MenuBookSelectComponent } from './form/menu-book-select/menu-book-select.component';

const routes: Routes = [
  {
    path: '',
    // canActivate: [AuthGuardService, InitGuardService],
    // canActivateChild: [AuthGuardService, InitGuardService],
    canActivate: [InitGuardService],
    canActivateChild: [InitGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'menu/gk-kangnam-04',
      },
      {
        path: 'menu/:room',
        pathMatch: 'full',
        component: MenuBookSelectComponent
      }
    ]
  },
  // {
  //   path: 'dev',
  //   loadChildren: './dev/dev.module#DevModule',
  //   canLoad: [AuthGuardService],
  //   canActivate: [AuthGuardService]
  // },
  {
    path: '**',
    redirectTo: 'menu/gk-kangnam-04'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
