import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MyMaterialModule } from './my-material/my-material.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { FormModule } from './form/form.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    // refer : https://github.com/scttcper/ngx-toastr
    ToastrModule.forRoot({
      timeOut: 30000,
      closeButton: false,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    MyMaterialModule,
    CoreModule,
    SharedModule,
    FormModule,
    AuthModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

