import fecha from 'fecha';
import { Diff } from 'deep-diff';
import { UnifiedOrderFood } from './schema';

fecha.i18n.dayNamesShort = ['일', '월', '화', '수', '목', '금', '토'];

/**
 * - 를 삽입한다.
 */
export function normalizeTel(telNo: string) {
  if (telNo == null || telNo === '') {
    return '번호없음';
  }
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
  telNo = telNo.replace(/^090/, '050');

  // 010- , 070-
  let matches = telNo.match(/^(0[17]0)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
  // 050-AAA?-BBBB
  matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050X-AAAA-BBBB
  matches = telNo.match(/^(050.)(.{4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  return telNo;
}

/**
 * '고스트키친 삼성점 04호'를 '삼성점 04호'로 변환한다.
 */
export function trimOrganization(text: string) {
  return text.replace(/^고스트키친 /, '');
}

/**
 * '고스트키친 삼성점 04호'를 '04호'로 변환한다.
 */
export function trimSite(text: string) {
  return text.replace(/^고스트키친 [^\s]+\s/, '');
}

/**
 *
 * @param dateStr  '2018-01-01T12:34:56+0900'
 */
export function weekdayKR(dateStr: string): string {
  // Safari는 +09:00은 지원해도 +0900은 지원하지 않는다.
  return fecha.format(fecha.parse(dateStr, 'YYYY-MM-DDTHH:mm:ssZZ'), 'ddd');
}

/**
 * 여러 형태의 시간을 ISO 형식의 문자열로 변환한다.
 * date2iso.test.ts에 사용예 확인
 */
export function toDate(date: string | number | Date): Date {
  if (date instanceof Date) {
    return date;
  } else if (typeof date === 'number') {
    if (date > 9999999999) {
      // 밀리초라면
      return new Date(date);
    } else {
      // 초라면
      return new Date(date * 1000);
    }
  } else {
    // Case 0. '2019-05-03T12:08:38+0900'
    let match = date.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+-]\d{2}):?(\d{2})$/);
    if (match) {
      return fecha.parse(date, 'YYYY-MM-DDTHH:mm:ssZZ');
    }

    // Case 1-1. '2019-05-03 12:08:38'
    // Case 1-2. '2019-05-03 12:08:38.0'
    match = date.match(/^(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})(\.0)?$/);

    if (match) {
      return fecha.parse(`${match[1]}T${match[2]}+0900`, 'YYYY-MM-DDTHH:mm:ssZZ');
    }

    // Case 2.
    match = date.match(/^(\d{4}\d{2}\d{2})T(\d{2}\d{2}\d{2})Z/);

    if (match) {
      return fecha.parse(`${match[1]}T${match[2]}+0000`, 'YYYYMMDDTHHmmssZZ');
    }

    // Case 3. 1559097490
    // 단위가 초라면 10자가 될 것이다.
    match = date.match(/^\d{10}$/);
    if (match) {
      return new Date(parseInt(date, 10) * 1000);
    }

    // 단위가 밀리초라면 13자가 될 것이다.
    match = date.match(/^\d{13}$/);
    if (match) {
      return new Date(parseInt(date, 10));
    }
  }

  throw TypeError(`Unexpected date format : ${date}`);
}

/**
 * 두 시각 차이를 계산해서 M:ss 형식으로 변환한다.
 * timestamp2 - timestamp1
 */
export function diffTime(timestamp1: string | number | Date, timestamp2: string | number | Date, round?: boolean) {
  if (round == null) {
    round = false;
  }

  const ret = {
    m: 0,
    s: 0,
    sStr: '00'
  };

  if (timestamp1 == null || timestamp2 == null) {
    return ret;
  }

  // Safari는 +09:00은 지원해도 +0900은 지원하지 않는다.
  const date1 = toDate(timestamp1).getTime();
  const date2 = toDate(timestamp2).getTime();

  const diffSec = Math.floor((date2 - date1) / 1000);

  let m = Math.floor(diffSec / 60);
  let s = diffSec % 60;

  if (round && s > 50) {
    s = 0;
    m += 1;
  }

  const sStr = s < 10 ? `0${s}` : `${s}`;

  ret.m = m;
  ret.s = s;
  ret.sStr = sStr;

  return ret;
}

/**
 * 1 -> 'A', 2 -> 'B', ....
 */
export function numberToAlphabet(num: number) {
  return String.fromCharCode(num + 64);
}

/**
 * deep-diff의 결과를 보기 좋게 보여준다.
 */
export function formatDiffs(diffs: ReadonlyArray<Diff<any>>) {
  let output = '';

  function formatDiff(diff: Diff<any>) {
    switch (diff.kind) {
      case 'N':
        return `[${diff.kind}] ${diff.path ? diff.path.join('.') : 'N/A'} : ${JSON.stringify(diff.rhs, null, 2)}`;
      case 'E':
        // tslint:disable-next-line: max-line-length
        return `[${diff.kind}] ${diff.path ? diff.path.join('.') : 'N/A'} : ${JSON.stringify(diff.lhs, null, 2)} => ${JSON.stringify(diff.rhs, null, 2)}`;
      case 'D':
        return `[${diff.kind}] ${diff.path ? diff.path.join('.') : 'N/A'} : ${JSON.stringify(diff.lhs, null, 2)}`;
      default:
        return `${JSON.stringify(diff, null, 2)}`;
    }
  }

  for (const diff of diffs) {
    switch (diff.kind) {
      case 'A':
        output += `[${diff.kind}] ${diff.path ? diff.path.join('.') : 'N/A'} : ( ${formatDiff(diff.item)} )\n`;
        break;

      // 'N' | 'E' | 'D'
      default:
        output += `${formatDiff(diff)}\n`;
        break;
    }
  }

  return output;
}

/**
 * toe-order-hub에서 가져왔다.
 *
 * foods가 1차 완성된 후에 다음의 2가지를 수행한다.
 * 1. 옵션을 포함해서 한 줄로 이름을 정리한 mergedName을 추가한다.
 * 2. 비싼 음식 먼저 앞에 위치하도록 정렬한다.
 *
 */
export function postProcessFoods(foods: UnifiedOrderFood[]) {
  // 1. mergedName 생성
  for (const food of foods) {
    if (food.foodOpts.length > 0) {
      const opt = food.foodOpts[0];
      let mergedName = opt.optName === '' ? food.foodName.trim() : `${food.foodName.trim()}_${opt.optName.trim()}`;
      // (사이드)잡채_사이드 같은 경우는 _사이드를 제거한다.
      mergedName = mergedName.replace(/(\(사이드\).+)_사이드$/, '$1');

      if (food.foodOpts.length > 1) {
          mergedName += ' [' + food.foodOpts.slice(1).map(foodOpt => foodOpt.optName.trim()).join(' + ') + ']';
      }
      food.mergedName = mergedName;
    } else {
      food.mergedName = food.foodName;
    }
  }

  // 2. 동일한 옵션 구성은 하나로 merge 한다.
  const keyedFoods: {
    [longkey: string]: UnifiedOrderFood
  } = {};

  // 새롭게 추가되는 food는 배열의 뒤 쪽에 위치하고 있고 _uiState 속성을 갖고 있을 수 있으므로
  // _uiState 속성을 살려주기 위해서 reverse()를 이용해서 배열의 마지막 요소가 우선권을 높게 한다.
  // isNew는 DOM 추가 애니메이션에 사용하고 있다ㅏ.
  for (const food of foods.reverse()) {
    // 옵션 가격까지 포함해서 비교한다.
    // optQty는 1로 가정하고 비교하지 않는다.
    const longkey = `${food.mergedName}${food.foodOpts.map(opt => String(opt.optPrice)).join('-')}`;
    const keyedFood = keyedFoods[longkey];

    // 중복 메뉴
    if (keyedFood) {
      keyedFood.foodQty += food.foodQty;
      keyedFood.foodOrdPrice = keyedFood.foodOpts.reduce((sum, foodOpt) => sum + foodOpt.optQty * foodOpt.optPrice, 0) * keyedFood.foodQty;

      // 중복이 있다는 것은 추가되었다는 뜻이므로 'added'로 변경한다.
      if (keyedFood._uiState === 'new') {
        keyedFood._uiState = 'added';
      }

      continue;
    }

    keyedFoods[longkey] = food;
  }

  foods = Object.values(keyedFoods);

  // 3. 가격이 비싼 메뉴가 앞에 위치하도록
  const sorted = foods.sort((food1: UnifiedOrderFood, food2: UnifiedOrderFood) => {
    const price1 = food1.foodOrdPrice; // foodQty가 포함된 가격
    const price2 = food2.foodOrdPrice;

    if (price1 > price2) {
      return -1;
    } else if (price1 < price2) {
      return 1;
    } else {
      if (food1.mergedName < food2.mergedName) {
        return -1;
      } else if (food1.mergedName > food2.mergedName) {
        return 1;
      }
      return 0;
    }
  });

  return sorted;
}


